@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #1D1E20;
  color: #DADADB;
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  body {
    max-width: 100%;
    padding: 0 20px;
  }
}

.header-section {
  margin: 30px 0;
  text-align: center;
}

.header-section h1 {
  font-size: 2.7rem;
  font-weight: 700;
}

.header-section p {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 10px;
}

.form-section {
  margin: 30px 0;
}

textarea,
button {
  width: 100%;
  border-radius: 5px;
  border: none;
}

.form-control {
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1rem;
  font-family: 'Poppins',
    sans-serif;
  outline: none;
  background-color: #343536;
  color: #DADADB;
  transition: all 0.5s ease-in-out;
}

.form-control:focus {
  border-left: 5px solid #CA228C;
  border-top: 5px solid #CA228C;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
}

.btn {
  background: #000000;
  color: #DADADB;
  padding: 20px 0;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn:hover {
  border-left: 5px solid #CA228C;
  border-top: 5px solid #CA228C;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
}

.hr-line {
  margin: 70px 0 20px;
  border: 1px solid #343536;
}

/* .answer-container {
  overflow: scroll;
  max-height: 500px;
} */

.answer-section {
  margin: 30px 0;
  position: relative;

}

.answer-section .question {
  background-color: #CA228C;
  padding: 20px;
}


.answer-section .answer {
  background-color: #343536;
  padding: 20px;
}

.copy-icon {
  position: absolute;
  background-color: #000000;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -10px;
  right: -10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.copy-icon:hover {
  background-color: #CA228C;
}